import React from 'react'
import { graphql } from 'gatsby'

import DefaultLayout from '@hoc/Layout/Default'
import BladeResolver from '@hoc/BladeResolver'
import Meta from '@hoc/Meta'
import { NewsHero } from '@components/NewsHero'
import { Author } from '@components/Author'

const Post = ({ data, pageContext }) => {
    const { theme, allPosts } = pageContext
    const { careers, post } = data

    const blockData = [{
        "id": "social",
        "color": "blue",
        "backgroundImage": null,
        "blocks": [
            {
                "__typename": "DatoCmsSocial",
                "id": post.id,
                "heading": "Be sure to follow us on social media!",
                "spaceTop": "medium",
                "spaceBottom": "medium",
                "backgroundGradient": "white"
            }
        ]
    }]

    return (
        <DefaultLayout {...{ theme }}>
            <Meta {...{ seo: post?.seo }} />

            {post.featuredImage && (
                <NewsHero {...{
                    image: post.featuredImage,
                    categories: post.categories,
                    meta: post.meta,
                    title: post.title,
                }} />
            )}

            <BladeResolver
                {...{
                    blades: post?.blades || [],
                    theme,
                    careers,
                    allPosts: allPosts?.edges?.map(({ node }) => node) || [],
                }}
            />

            {post?.authorOfPost && (
                <Author {...{
                    ...post?.authorOfPost
                }} />
            )}

            <BladeResolver {...{
                key: '__DatoCmsSocialLinks',
                id: '__DatoCmsSocialLinks',
                blades: blockData,
                theme,
            }} />
        </DefaultLayout>
    )
}

export const query = graphql`
    query getPost($id: String) {
        # Comment out the careers query
        #
        # careers: allWorkableJob(limit: 100) {
        #     edges {
        #       node {
        #            id
        #            state
        #            application_url
        #            url
        #            department
        #            description
        #            employment_type
        #            shortcode
        #            title
        #            created_at
        #        }
        #    }
        # }
        #
        post: datoCmsPost(id: { eq: $id }) {
            id
            slug
            title
            seo {
                description
                title
                twitterCard
                image {
                    url
                }
            }
            featuredImage {
                gatsbyImageData
            }
            categories {
                name
            }
            meta {
                firstPublishedAt(formatString: "DD MMM, YYYY")
            }
            blades {
                id: bladeId
                color
                backgroundImage {
                    gatsbyImageData
                }
                blocks {
                    ... on DatoCmsHero {
                        ...Hero
                    }
                    ... on DatoCmsHeroSimple {
                        ...HeroSimple
                    }
                    ... on DatoCmsHeroThumbnail {
                        ...HeroThumbnail
                    }
                    ... on DatoCmsHeroNews {
                        ...HeroNews
                    }
                    ... on DatoCmsThumbnail {
                        ...Thumbnail
                    }
                    ... on DatoCmsVideoThumbnail {
                        ...VideoThumbnail
                    }
                    ... on DatoCmsIconCard {
                        ...IconCards
                    }
                    ... on DatoCmsCareer {
                        ...Careers
                    }
                    ... on DatoCmsCareerFull {
                        ...CareersFull
                    }
                    ... on DatoCmsLatestNews {
                        ...LatestNews
                    }
                    ... on DatoCmsSocial {
                        ...Social
                    }
                    ... on DatoCmsNewsGrid {
                        ...NewsGrid
                    }
                    ... on DatoCmsEditor {
                        ...Editor
                    }
                }
            }
            authorOfPost {
                authorName
                authorBio
                authorImage {
                    gatsbyImageData(placeholder: BLURRED, width: 125, height: 125)
                    alt
                }
                authorRole
                authorSocials {
                    socialMediaPlatform
                    socialMediaUrl
                }
            }
        }
    }
`

export default Post
