import { getImage, GatsbyImage } from 'gatsby-plugin-image'
import React from 'react'
import BackgroundImage from 'gatsby-background-image'
import { convertToBgImage } from 'gbimage-bridge'
import { graphql, useStaticQuery } from 'gatsby'
import Icon from '@components/Icon'
import Link from '@components/Link'
import Lottie from '@components/Lottie'
import constellationLight from '@assets/constellation-light.json'

export const Author = ({ authorBio, authorImage, authorName, authorRole, authorSocials = [] }) => {
    const { file } = useStaticQuery(graphql`
    query background {
        file(relativePath: {eq: "careers-bg.jpg"}) {
            childImageSharp {
                gatsbyImageData(placeholder: BLURRED)
            }
        }
    }`)

    const _background = getImage(file.childImageSharp.gatsbyImageData)
    const _bg = convertToBgImage(_background)
    const _image = getImage(authorImage.gatsbyImageData)

    return (
        <BackgroundImage {..._bg} tag="Section" className="overflow-hidden">
            <div className="relative overflow-hidden w-full h-full">
                <span
                    className={`radial-gradient blue-light block w-[80rem] h-[80rem] absolute top-0 left-1/4 transform -translate-x-1/2 -translate-y-1/4 md:-translate-y-1/2 opacity-30 z-0 pointer-events-none`}
                />
                <div className="container w-11/12 py-20 lg:py-24 max-w-6xl mx-auto relative z-10">
                    <div className="flex items-center justify-center flex-col lg:grid xl:gap-20 lg:grid-cols-12 gap-10 lg:items-start">

                        <div className="bg-eerieblack p-4 pr-6 xs:pr-8 sm:pr-20 lg:p-5 flex flex-row flex-nowrap gap-4 sm:gap-8 shadow-lg lg:col-span-5 mx-auto lg:w-full relative group overflow-hidden hover:scale-[101%] transition-all duration-300">
                            <GatsbyImage image={_image} alt={authorImage?.alt ? authorImage.alt : 'Author'} />
                            <div className="py-2">
                                <h2 className="font-body text-blue-light text-xl font-semibold uppercase tracking-normal">{authorName}</h2>
                                {authorRole && <p className="text-white font-body">{authorRole}</p>}
                                {authorSocials && (
                                    <div className="flex flex-row flex-wrap mt-3">
                                        {authorSocials.map(({ socialMediaPlatform, socialMediaUrl }, i) => {
                                            return (
                                                <Link key={i} linkType="external" to={socialMediaUrl} className="text-white hover:text-blue-light transition-colors duration-300">
                                                    <Icon name={socialMediaPlatform} className="w-8 h-8" />
                                                </Link>
                                            )
                                        })}
                                    </div>
                                )}
                            </div>

                            <span className="image-triangle block w-12 h-12 absolute top-0 right-0 bg-jet z-20 group-hover:-top-1 group-hover:-right-1 transition-all duration-300" />
                        </div>
                        <div className="lg:col-span-7 text-center max-w-[550px] lg:max-w-none lg:text-left">
                            <p className="font-body text-gainsboro text-lg lg:text-xl">{authorBio}</p>
                        </div>
                    </div>
                </div>

                <Lottie
                    {...{
                        autoplay: true,
                        loop: false,
                        animation: constellationLight,
                        className:
                            'max-w-[36rem] w-full h-auto absolute top-1/2 lg:top-6 right-16 rotate-[2deg] z-20',
                    }}
                />
            </div>
        </BackgroundImage>
    )
}