import React from 'react'
import BackgroundImage from 'gatsby-background-image'
import { convertToBgImage } from 'gbimage-bridge'
import { getImage } from 'gatsby-plugin-image'
import Lottie from '@components/Lottie'
import constellationLight from '@assets/constellation-light.json'


export const NewsHero = ({ image, categories, meta, title }) => {
    const _image = getImage(image.gatsbyImageData)
    const _bg = convertToBgImage(_image)
    return (
        <BackgroundImage {..._bg} Tag="section">
            <div className="pt-12 md:pt-24 pb-20 min-h-[375px] md:min-h-[450px] lg:min-h-[600px] xl:min-h-[625px] bg-black bg-opacity-50 flex items-center justify-between flex-col text-center relative">
                <span className="font-body block relative text-white font-semibold uppercase text-2xl">{meta.firstPublishedAt}
                    <span className="heading-triangle block w-6 h-5 absolute top-1 -left-8 transform bg-white rotate-180 drop-shadow-md" /></span>

                <div className="w-11/12 mx-auto">
                    {categories && categories.map((category, _index) => (
                        <div className="bg-eerieblack pl-6 pr-7 clip-corner py-1.5 text-white font-body uppercase font-semibold relative min-w-[125px] text-center shadow-md w-fit mx-auto mb-6" key={_index}>
                            <span className="block relative z-10 tracking-wider text-lg">{category.name}</span>
                            <span className="max-w-[35px] absolute top-0 right-0 z-0 w-full h-full bg-jet" />
                        </div>
                    ))}
                    <h1 className="inline-block relative font-display font-semibold text-4xl md:text-5xl lg:text-6xl uppercase text-white tracking-wider drop-shadow-md max-w-[1000px] mx-auto">{title}
                        <span className="heading-triangle block w-6 h-5 absolute top-1 -right-8 transform bg-white drop-shadow-md" />
                    </h1>
                </div>

                <span
                    className={`line-gradient hidden white sm:block w-6 h-[150%] absolute -top-20 left-[15%] right-0 transform origin-center rotate-[-45deg] 2xl:-top-32 2xl:left-[12%] opacity-30 z-0 pointer-events-none`}
                />
            </div>

            <Lottie
                {...{
                    autoplay: true,
                    loop: false,
                    animation: constellationLight,
                    className:
                        'max-w-[46rem] w-full h-auto absolute lg:-top-[25%] lg:-left-[25%] xl:-top-52 xl:-left-52 rotate-[-20deg] z-20',
                }}
            />

            <Lottie
                {...{
                    autoplay: true,
                    loop: false,
                    animation: constellationLight,
                    className:
                        'max-w-[46rem] w-full h-auto absolute rotate-[-30deg] lg:-bottom-40 lg:-right-[5%] xl:-right-10 z-20',
                }}
            />
        </BackgroundImage >
    )
}